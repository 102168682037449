import React from 'react';
import {useUser} from "helpers/context/ContextApi";
import TopNavigation from "components/top-navigation/TopNavigation";
import {getUserProfileDetails, logoutUserRequest} from "data/userRequests";
import {getActiveStatusCaption} from "helpers/Helpers";
import "./Profile.css";
import {useNavigate} from "react-router-dom";

interface UserState {
    username: string,
    role_id: string,
    user_id: string,
    profile_picture_url: string,
}

interface UserFullDetails {
    username: string,
    role_id: string,
    user_id: string,
    profile_picture_url: string,
    is_user_active: string,
    email: string,
    first_name: string,
    last_name: string,
    creation_date: string,
    last_login_date: string
}

function Profile() {
    // @ts-ignore
    const { state, dispatch } = useUser();
    const navigate = useNavigate();

    const [userContextDetails, setUserContextDetails] = React.useState<UserState>({username: "", role_id: "", user_id: "", profile_picture_url: ""})
    const [userAllDetails, setUserAllDetails] = React.useState<UserFullDetails | null>(null);
    const [rerender, setRerender] = React.useState<boolean>(false);
    const [profilePicture, setProfilePicture] = React.useState<string>("/profile_img_placeholder.jpg");

    /*
    * Used only one time when component is rendered for the first time:
    * */
    React.useEffect(() => {
        /*
        * populate at least basic details that are already in context in case that extensive details request failed.
        * */
        setUserContextDetails({
            username: state.user?.username,
            role_id: state.user?.role_id,
            user_id: state.user?.user_id,
            profile_picture_url: state.user?.photo_url
        });

        fetchUserProfileDetails();
    }, [rerender]);

    const applyProfilePicture = (pictureUrl: string) => {
        if (pictureUrl !== null && pictureUrl.length > 0) {
            setProfilePicture(pictureUrl);
        }
    };

    const fetchUserProfileDetails = async () => {
        const response = await getUserProfileDetails(state.user?.user_id);

        /*
        * set extended details into context:
        * */
        if (response?.status == "200") {
                // set profile picture:
                applyProfilePicture(response.profile_picture_url);

                dispatch({
                    type: "LOGIN",
                    payload: {
                        role_id: response.role_id,
                        user_id: response.user_id,
                        username: response.username,
                        is_user_active: response.is_user_active,
                        email: response.email,
                        first_name: response.first_name,
                        last_name: response.last_name,
                        creation_date: response.creation_date,
                        last_login_date: response.last_login_date
                    }
                });
        }
        setUserAllDetails(response);
    }

    const logoutUser = async () => {
        const response = await logoutUserRequest();
        if (response?.status == "200") {
            dispatch({type: "LOGOUT"});
            navigate("/login")
        }
    }

    return (
        <div className={"pageWrapper"}>
            <TopNavigation />
            <div className={"pageContent"}>
                <div className={"userTopSection"}>
                    <div className={"header"}>
                        <span data-testid={"profileHeaderTestId"}>{"Profile Page of "}</span>
                        <span className={"mainCaption"}>{userContextDetails.username}</span>
                    </div>
                    <div className={"userTopCaption"}>
                        <span data-testid={"profileUsernameTestId"}>{"Username: "}</span>
                        <span className={"mainCaption"}>{userContextDetails.username}</span>
                    </div>
                    <div className={"userTopCaption"}>
                        <span data-testid={"profileUserTypeTestId"}>{"User type: "}</span>
                        <span className={"mainCaption"}>{userContextDetails.role_id == "1" ? "Admin" : "User"}</span>
                    </div>
                </div>
                {userAllDetails &&
                    <div className={"containerContent"}>
                        <img className={"photoFrame"} src={profilePicture} alt={"no photo.."} />
                        <div className={"userBottomCaption"}>
                            <span>{"Account status: "}</span>
                            <span className={"mainCaption"}>{getActiveStatusCaption(userAllDetails.is_user_active)}</span>
                        </div>
                        <div className={"userBottomCaption"}>
                            <span>{"Email address: "}</span>
                            <span className={"mainCaption"}>{userAllDetails.email}</span>
                        </div>
                        <div className={"userBottomCaption"}>
                            <span>{"Name: "}</span>
                            <span className={"mainCaption"}>{`${userAllDetails.first_name} ${userAllDetails.last_name}`}</span>
                        </div>
                        <div className={"userBottomCaption"}>
                            <span>{"Registration date: "}</span>
                            <span className={"mainCaption"}>{userAllDetails.creation_date}</span>
                        </div>
                        <div className={"userBottomCaption"}>
                            <span>{"Last login date: "}</span>
                            <span className={"mainCaption"}>{userAllDetails.last_login_date}</span>
                        </div>
                        <div className={"userBottomCaption"}>
                            <span>{"User id: "}</span>
                            <span className={"mainCaption"}>{userContextDetails.user_id}</span>
                        </div>
                    </div>
                }
                <div className={"logoutWrapper"}>
                    <span className={"logoutCaption"}>Logout current user?</span>
                    <button className={"logoutButton"} onClick={logoutUser} data-testid={"logoutButtonTestId"}>Logout</button>
                </div>
            </div>

        </div>
    );
}

export default Profile;
